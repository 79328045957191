import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
      Everts, K.L., and Lacy, M.L. 1990. Inﬂuence of Environment on Conidial Concentration of Alternaria porri in Air and on Purple Blotch Incidence on Onion. Phytopathology. 80: 1387-1391. 
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
      Lacy, M.L., and Pontius, G.A. 1983. Prediction of Weather-Mediated Release of Conidia of Botrytis squamosa from Onion Leaves in the Field. Phytopathology. 73: 670-676. 
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
      Jesperson, G.D., and Sutton, J.C. 1987. Evaluation of a Forecaster for Downy Mildew of Onion (Allium cepa L.). Crop Protection. 6: 95-103. 
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
      Vincelli, P.C., and Lorbeer, J.W. 1989. Forecasting Spore Episodes of Botrytis squamosa in Commercial Onion Fields of New York Phytopathology. 78: 966-970. 
      </p>
    </>
  )
}
