export const lookupTable = {
  0: [90, 92, 94, 96, 97, 97, 98, 98, 98, 99, 99, 99, 99, 99, 100, 100, 100],
  0.25: [88, 91, 93, 95, 96, 97, 97, 98, 98, 99, 99, 99, 99, 99, 99, 100, 100],
  0.5: [86, 88, 91, 94, 95, 96, 97, 97, 97, 98, 98, 98, 99, 99, 99, 99, 99],
  0.75: [83, 86, 89, 92, 94, 95, 96, 96, 97, 97, 98, 98, 98, 99, 99, 99, 99],
  1: [79, 83, 87, 90, 92, 94, 95, 96, 96, 97, 97, 98, 98, 98, 99, 99, 99],
  1.25: [75, 80, 84, 87, 90, 92, 94, 95, 96, 96, 97, 97, 98, 98, 98, 99, 99],
  1.5: [67, 78, 71, 85, 87, 90, 92, 94, 95, 96, 96, 97, 97, 98, 98, 98, 98],
  1.75: [60, 69, 77, 82, 85, 87, 90, 92, 94, 95, 96, 96, 97, 97, 97, 98, 98],
  2: [51, 62, 71, 78, 82, 85, 88, 90, 93, 94, 95, 96, 96, 97, 97, 97, 98],
  2.25: [42, 55, 65, 74, 79, 82, 85, 88, 91, 93, 94, 95, 95, 96, 96, 97, 97],
  2.5: [31, 46, 59, 68, 75, 79, 83, 85, 89, 91, 93, 94, 94, 95, 95, 96, 97],
  2.75: [23, 36, 50, 62, 70, 76, 80, 83, 86, 89, 91, 93, 93, 94, 95, 95, 96],
  3: [17, 28, 40, 53, 64, 71, 77, 80, 84, 86, 89, 91, 92, 93, 94, 95, 95],
  3.25: [12, 20, 32, 44, 56, 65, 72, 77, 81, 84, 86, 89, 91, 92, 93, 94, 94],
  3.5: [7, 15, 25, 37, 48, 58, 66, 72, 77, 81, 84, 86, 89, 90, 92, 92, 93],
  3.75: [4, 10, 20, 29, 39, 50, 60, 67, 73, 78, 81, 84, 86, 88, 89, 91, 92],
  4: [1, 6, 14, 22, 32, 43, 52, 62, 68, 74, 77, 81, 83, 85, 87, 88, 90],
  4.25: [0, 4, 9, 17, 25, 36, 45, 54, 62, 68, 74, 76, 80, 82, 84, 85, 87],
  4.5: [0, 2, 7, 12, 20, 28, 38, 46, 54, 61, 67, 72, 75, 78, 80, 82, 83],
  4.75: [0, 1, 4, 9, 15, 22, 31, 39, 47, 53, 59, 64, 71, 72, 75, 78, 80],
  5: [0, 0, 2, 6, 11, 17, 25, 32, 39, 44, 51, 56, 60, 68, 68, 71, 74],
  5.25: [0, 0, 0, 3, 8, 13, 19, 25, 32, 38, 44, 48, 52, 56, 61, 64, 66],
  5.5: [0, 0, 0, 1, 5, 9, 15, 20, 25, 31, 37, 40, 45, 49, 52, 55, 58],
  5.75: [0, 0, 0, 0, 3, 6, 10, 16, 20, 25, 29, 33, 37, 41, 45, 48, 50],
  6: [0, 0, 0, 0, 1, 4, 7, 11, 15, 19, 23, 26, 30, 33, 37, 41, 42],
  6.25: [0, 0, 0, 0, 0, 2, 4, 7, 11, 14, 17, 20, 24, 27, 30, 33, 35],
  6.5: [0, 0, 0, 0, 0, 0, 2, 4, 7, 10, 12, 15, 18, 20, 21, 25, 28],
  6.75: [0, 0, 0, 0, 0, 0, 0, 1, 4, 6, 9, 10, 12, 14, 16, 19, 23],
  7: [0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 5, 6, 8, 9, 10, 14, 18],
  7.25: [0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 3, 4, 5, 7, 10, 13],
  7.5: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 3, 4, 6, 9],
  7.75: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 3, 5],
  8: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2],
}
