import { format } from 'date-fns'
import React from 'react'
import { CSVLink } from 'react-csv'

const th = `px-6 py-3 text-xs text-white border-b border-r border-gray-200 bg-secondary-800 shadow-sm`

export default function ResultsTable({
  station,
  tableData,
  dateOfInterest,
  title,
  csv,
}) {
  if (!tableData) return null

  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-3">
        <h2 className="text-xl font-semibold text-gray-600 md:text-2xl">
          {title}
        </h2>

        <div className="flex justify-center rounded-md shadow-sm">
          <button
            type="button"
            aria-label="download results table data in csv format"
            className="inline-flex items-center p-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md sm:px-3 sm:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
            </svg>

            <CSVLink
              className="text-white no-underline"
              filename={`${title} Seven Day Summary.csv`}
              data={csv}
            >
              <span className="hidden text-white sm:inline-block">
                Download
              </span>{' '}
              <span className="text-white">CSV</span>
            </CSVLink>
          </button>
        </div>
      </div>

      <div className="flex flex-col my-4 sm:flex-row sm:justify-between sm:items-center">
        <div className="flex items-center">
          <span className="inline-block py-2 mr-4">
            <a
              className="text-xs sm:text-sm"
              href={`http://forecast.weather.gov/MapClick.php?textField1=${station.lat}&textField2=${station.lon}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Forecast Details
            </a>
          </span>
        </div>

        <div className="flex flex-col mt-2 text-sm lg:mt-0 lg:items-center lg:flex-row">
          <span className="mb-2 mr-3 font-bold tracking-wider text-gray-700 lg:mb-0">
            Favorability in past 7 days:
          </span>
          <div className="grid grid-cols-5 gap-1">
            <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-xs lg:text-sm bg-white text-gray-800 font-semibold font">
              Low
            </span>
            <span className="cell level-1">Slight</span>
            <span className="cell level-2">Moderate</span>
            <span className="cell level-3">High</span>
            <span className="cell level-4">Extreme</span>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-4">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full align-middle border-b border-gray-200 shadow lg:rounded-lg">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className={`${th}`} rowSpan="4">
                    Date <small>({dateOfInterest.year})</small>
                  </th>
                  <th className={th} rowSpan="1" colSpan="7">
                    Past 7 Days
                  </th>
                </tr>

                <tr>
                  <th className={`${th}`} colSpan="4">
                    Botrytis leaf blight
                  </th>
                  <th className={`${th}`} colSpan="1" rowSpan="2">
                    Downy Mildew
                  </th>
                  <th className={`${th}`} colSpan="2" rowSpan="2">
                    Purple Blotch (PRI)
                  </th>
                </tr>

                <tr>
                  <th className={`${th}`} colSpan="2">
                    Michigan Botrytis forecast (BLB)
                  </th>
                  <th className={`${th}`} colSpan="2">
                    Modified Blight Alert (IPI)
                  </th>
                </tr>

                <tr>
                  <th className={`${th}`}>Number of days favorable</th>
                  <th className={`${th}`}>Average rating per day</th>
                  <th className={`${th}`}>Number of days favorable</th>
                  <th className={`${th}`}>Average rating per day</th>
                  <th className={`${th}`}>Number of days favorable</th>
                  {/* <th className={`${th}`}>Average rating per day</th> */}
                  <th className={`${th}`}>Number of days favorable</th>
                  <th className={`${th}`}>Average rating per day</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {tableData.map((day, i) => {
                  return (
                    <tr
                      key={day.ms}
                      className={
                        i === 2 ? `font-extrabold text-center` : `text-center`
                      }
                    >
                      <td className={`td h-14`}>
                        <span className={`flex flex-col items-center cell`}>
                          {format(day.date, 'LLLL d')}
                          <small className="font-bold text-gray-500">
                            {day.isForecast ? 'Forecast' : ''}
                          </small>
                        </span>
                      </td>

                      <td className={`td`}>
                        <span className={`cell ${day.blb7DaySumBgColor}`}>
                          {day.blb7DaySum}
                        </span>
                      </td>

                      <td className={`td`}>
                        <span className={`cell ${day.blbAvgRiskBgColor}`}>
                          {day.blbAvgRisk}
                        </span>
                      </td>

                      <td className={`td`}>
                        <span className={`cell ${day.ipi7DaySumBgColor}`}>
                          {day.ipi7DaySum}
                        </span>
                      </td>

                      <td className={`td`}>
                        <span className={`cell ${day.ipiAvgRiskBgColor}`}>
                          {day.ipiAvgRisk}
                        </span>
                      </td>

                      <td className={`td`}>
                        <span
                          className={`cell ${day.sporulation7DaySumBgColor}`}
                        >
                          {day.sporulation7DaySum}
                        </span>
                      </td>

                      <td className={`td`}>
                        <span className={`cell ${day.pri7DaySumBgColor}`}>
                          {day.pri7DaySum}
                        </span>
                      </td>

                      <td className={`td`}>
                        <span className={`cell ${day.priAvgRiskBgColor}`}>
                          {day.priAvgRisk}
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
