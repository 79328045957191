import { format, getDayOfYear } from 'date-fns'
import mean from 'lodash.mean'
import { lookupTable } from './_lookupTable'

function vaporPressure(tempF: number, rhum: number) {
  const tempK = 0.555 * (tempF - 32) + 273.16
  const esat =
    10 **
    (23.832241 -
      5.02808 * Math.log10(tempK) -
      0.00000013816 * 10 ** (11.344 - 0.0303998 * tempK) +
      0.008132801 * 10 ** (3.49149 - 1302.8844 / tempK) -
      2949.076 / tempK)

  const dewVapor = (rhum * esat) / 100

  return Number((esat - dewVapor).toFixed(3))
}

function modifiedBlightAlert(day, plantingDateDoy: number) {
  // Daily values >= 7 have a red cell background color.
  const THRESHOLD: number = 7

  const { dlyAvgTemp, dlyRhumGE90Count, dayOfYear } = day
  const dlyAvgTempC = 0.555 * (dlyAvgTemp - 32)
  const efi =
    -0.357 +
    0.077 * dlyAvgTempC -
    0.0023 * dlyAvgTempC ** 2 +
    0.0065 * dlyRhumGE90Count +
    0.0011 * dlyRhumGE90Count ** 2 +
    0.0022 * (dlyAvgTempC * dlyRhumGE90Count)
  const day33 = dayOfYear - plantingDateDoy - 33
  let ipi: number = 0
  if (day33 < 47) {
    ipi = 7.83 * efi * (-0.0563 + 0.0626 * day33 - 0.00067 * day33 ** 2)
  } else {
    ipi = 11.12 * efi
  }
  const favorable = ipi >= THRESHOLD ? true : false
  return {
    ipiRisk: Number(ipi.toFixed(2)),
    ipiRiskBgColor: favorable ? 'level-4' : 'level-1',
    favorable,
  }
}

function michiganBotrytisForecast(day) {
  const THRESHOLD: number = 50
  const { dlyAvgTemp3Days, dlyAvgVpd3Days } = day
  const dlyAvgTemp3DaysC = 0.555 * (dlyAvgTemp3Days - 32)

  let sporeIndex: number = 0
  let iTemp = 0
  if (dlyAvgTemp3DaysC <= 7) {
    sporeIndex = 0
  } else {
    if (dlyAvgTemp3DaysC > 24) {
      iTemp = 24
    } else {
      iTemp = Math.floor(dlyAvgTemp3DaysC)
    }

    if (dlyAvgVpd3Days >= 8) {
      sporeIndex = lookupTable['8'][iTemp - 8]
    } else {
      const keys = Object.keys(lookupTable)
        .map((k) => +k)
        .sort()
      const k = keys.find(
        (k, i) => dlyAvgVpd3Days >= k && dlyAvgVpd3Days < keys[i + 1],
      )
      const key = String(k)
      sporeIndex = lookupTable[key][iTemp - 8]
    }
  }
  const favorable = sporeIndex >= THRESHOLD ? true : false
  return {
    sporeIndex,
    sporeIndexBgColor: favorable ? 'level-4' : 'level-1',
    favorable,
  }
}

function downyMildew(hourlyData) {
  let mildew = []
  let start_hour = 8
  let end_hour = 8
  let start = 0
  let temp1_sum = 0
  let temp1_cnt = 0
  let temp2_sum = 0
  let temp2_cnt = 0
  let rhum_cnt = 0
  let prcp_cnt = 0
  let miss = -999

  for (let i = 0; i < hourlyData.length; i++) {
    const hrData = hourlyData[i]
    const { date, temp, prcp, rhum } = hrData
    const hr = date.getHours()

    // console.log({ start, hr })
    if (!start && hr !== start_hour) {
      continue
    } else {
      start = 1
    }

    if (hr >= 20 || hr <= 8) {
      temp1_sum = temp1_sum + temp
      temp1_cnt = temp1_cnt + 1
    }

    if (hr >= 9 && hr <= 20) {
      temp2_sum = temp2_sum + temp
      temp2_cnt = temp2_cnt + 1
    }

    if (hr >= 1 && hr <= 6) {
      if (prcp > 0) prcp_cnt = prcp_cnt + 1
    }

    if (hr >= 2 && hr <= 6) {
      if (rhum < 95) rhum_cnt = rhum_cnt + 1
    }

    if (hr === end_hour) {
      let temp1_avg = 0
      if (temp1_cnt === 13) {
        temp1_avg = temp1_sum / temp1_cnt
      } else {
        temp1_avg = miss
      }

      let temp2_avg = 0
      if (temp2_cnt == 13) {
        temp2_avg = temp2_sum / temp2_cnt
      } else {
        temp2_avg = miss
      }

      let mildew_status = ''
      if (temp1_avg !== miss && temp2_avg !== miss) {
        if (
          prcp_cnt > 0 ||
          rhum_cnt > 0 ||
          temp1_avg < 40 ||
          temp1_avg > 75 ||
          temp2_avg > 75
        ) {
          mildew_status = 'Not favorable'
        } else {
          mildew_status = 'Favorable'
        }
      } else {
        mildew_status = 'Unavailable'
      }

      // mildew[date] = mildew_status
      mildew.push({
        date,
        sporulation: mildew_status,
      })

      temp1_sum = 0.0
      temp1_cnt = 0
      temp2_sum = 0.0
      temp2_cnt = 0
      prcp_cnt = 0
      rhum_cnt = 0

      if (temp !== miss) {
        temp2_sum = temp2_sum + temp
        temp2_cnt = temp2_cnt + 1
      } else {
        temp2_cnt = miss
      }
    }
  }

  return mildew
}

function purpleBlotchNew(hourlyData) {
  let dly_altvar = []
  let end_hour = 10
  let minrh_end_hour = 12
  let start_hour = 22
  let start = 0
  let temp_max = -1000
  let temp_min = 1000
  let rh95_cnt = 0
  let rh_min = 100

  for (let i = 0; i < hourlyData.length; i++) {
    const hrData = hourlyData[i]
    const { date, temp, rhum } = hrData
    const hr = date.getHours()

    if (!start && hr !== start_hour) {
      continue
    } else {
      start = 1
    }

    if (hr >= start_hour || hr <= end_hour) {
      // # get max and min temperature for "day"
      if (temp > temp_max) temp_max = temp
      if (temp < temp_min) temp_min = temp
      // # count hours >= 95 and get min rh
      if (rhum >= 95) rh95_cnt = rh95_cnt + 1
      if (rhum < rh_min) rh_min = rhum
    }
    if (hr > end_hour && hr <= minrh_end_hour) {
      // # extend range for min rh
      if (rhum < rh_min) rh_min = rhum
      // # end of "day" summary
      if (hr === minrh_end_hour) {
        dly_altvar.push({
          date,
          dayOfYear: getDayOfYear(date),
          temp_max,
          temp_min,
          rh95_cnt,
          rh_min,
        })
        temp_max = -9999
        temp_min = 9999
        rh95_cnt = 0
        rh_min = 100
      }
    }
  }

  return dly_altvar
}

function getAlt(dailyData, plantingDateDoy) {
  let res = []
  let aindex = []

  for (let i = 0; i < dailyData.length; i++) {
    const { date, dayOfYear, temp_max, temp_min, rh95_cnt, rh_min } =
      dailyData[i]
    let tmax_c = 0.555 * (temp_max - 32)
    let tmin_c = 0.555 * (temp_min - 32)

    let temp_index
    if (tmax_c <= 30 && tmin_c <= 30 && tmax_c >= 13 && tmin_c >= 13) {
      temp_index = 2
    } else if (tmax_c <= 30 && tmax_c <= 30 && tmax_c >= 7 && tmin_c >= 7) {
      temp_index = 1
    } else {
      temp_index = 0
    }

    let nite_rh_index
    if (rh95_cnt >= 8) {
      nite_rh_index = 3
    } else if (rh95_cnt >= 4) {
      nite_rh_index = 2
    } else {
      nite_rh_index = 1
    }

    let rel_rh_index
    if (rh_min < 75) {
      rel_rh_index = 2
    } else if (rh_min <= 85) {
      rel_rh_index = 1
    } else {
      rel_rh_index = 0
    }

    aindex.push({ date, dayOfYear, temp_index, nite_rh_index, rel_rh_index })
  }

  for (let i = aindex.length - 1; i > 1; i--) {
    let day1 = aindex[i].temp_index + aindex[i].nite_rh_index
    let day2 = aindex[i - 1].temp_index + aindex[i - 1].nite_rh_index
    let day3 = aindex[i - 2].temp_index + aindex[i - 2].nite_rh_index
    let alt_index = (day1 + day2 + day3) / 3 + aindex[i].rel_rh_index
    res.push({
      date: aindex[i].date,
      dayOfYear: aindex[i].dayOfYear,
      alt_index: Number(alt_index.toFixed(1)),
    })
  }
  const ciccio = res.sort((a, b) => {
    if (a.dayOfYear < b.dayOfYear) return -1
    if (a.dayOfYear > b.dayOfYear) return 1
    return 0
  })
  const index = ciccio.findIndex((d) => d.dayOfYear === plantingDateDoy)
  return ciccio.slice(index).map((d) => {
    const favorable = d.alt_index >= 5.7 ? true : false
    return {
      priRisk: d.alt_index,
      favorable,
      priRiskBgColor: favorable ? 'level-4' : 'level-1',
    }
  })
}

export default function onionDiseasesLogic(
  data,
  pDate: string,
  dateOfInterest,
) {
  if (!data || !pDate) return

  const { dailyData, hourlyData, hourlyFcstData } = data

  // Planting date -----------------------------------------------------------
  const plantingDateDoy = getDayOfYear(new Date(`${pDate}T00:00`))
  if (dateOfInterest.dayOfYear < plantingDateDoy) return

  const plantingDate = dailyData.find((d) => d.dayOfYear === plantingDateDoy)

  // "day" is 24 hour period 7am to 6am ending on the date of interest --------
  const dailyDataFromPlantingDate = data.dailyData.slice(plantingDateDoy - 2)

  const mainData = dailyDataFromPlantingDate
    .map((day, i, arr) => {
      const { date, dayOfYear, ms, isForecast, pop12 } = day

      let temps: number[] = []
      let rhums: number[] = []
      let dlyAvgTemp: number = 0
      let dlyRhumGE90Count: number = 0
      let hrlyVpds: number[] = []
      let dlyAvgVpd: number = 0
      let pop10pm: number = 0
      let pop10am: number = 0

      if (i > 0) {
        const dayBefore = arr[i - 1]

        temps = [...dayBefore.temp.slice(6), ...day.temp.slice(0, 6)].filter(
          (t) => t !== 'M',
        )

        dlyAvgTemp = mean(temps)

        rhums = [...dayBefore.rhum.slice(6), ...day.rhum.slice(0, 6)].filter(
          (t) => t !== 'M',
        )

        hrlyVpds = temps.map((t, i) => vaporPressure(t, rhums[i]))
        dlyAvgVpd = mean(hrlyVpds)

        dlyRhumGE90Count = rhums.filter((r) => r >= 90).length

        if (pop12) {
          pop10pm = dayBefore.pop12[21]
          pop10am = day.pop12[9]
            ? day.pop12[9]
            : day.pop12.find((d) => typeof d === 'number')
        }
      }

      return {
        date,
        dayOfYear,
        ms,
        isForecast,
        temps,
        dlyAvgTemp,
        hrlyVpds,
        dlyAvgVpd,
        dlyRhumGE90Count,
        pop10pm,
        pop10am,
      }
    })
    .slice(1)
    .map((day, i, arr) => {
      let dlyAvgTemp3Days: number = 0
      let dlyAvgVpd3Days: number = 0
      if (i < 3) {
        const temps = arr.slice(0, i + 1).map((d) => d.dlyAvgTemp)
        dlyAvgTemp3Days = mean(temps)
        const vpds = arr.slice(0, i + 1).map((d) => d.dlyAvgVpd)
        dlyAvgVpd3Days = mean(vpds)
      } else {
        const temps = arr.slice(i - 2, i + 1).map((d) => d.dlyAvgTemp)
        dlyAvgTemp3Days = mean(temps)
        const vpds = arr.slice(i - 2, i + 1).map((d) => d.dlyAvgVpd)
        dlyAvgVpd3Days = mean(vpds)
      }
      return {
        ...day,
        dlyAvgTemp3Days,
        dlyAvgVpd3Days,
      }
    })

  const blb = mainData
    .map((day) => michiganBotrytisForecast(day))
    .map((day, i, arr) => {
      let a: string | number
      let b: string = ''

      let c: string | number
      let d: string = ''

      if (i < 7) {
        a = '-'
        c = '-'
      } else {
        const values = arr.slice(i - 6, i + 1)

        a = values.filter((d) => d.favorable).length
        if (a >= 1 && a <= 2) b = 'level-1'
        if (a >= 3 && a <= 4) b = 'level-2'
        if (a >= 5 && a <= 7) b = 'level-3'

        c = Math.round(mean(values.map((d) => d.sporeIndex)))
        if (c >= 50 && c <= 74) d = 'level-2'
        if (c >= 75) d = 'level-4'
      }

      return {
        blbRisk: day.sporeIndex,
        blbRiskBgColor: day.sporeIndexBgColor,
        blb7DaySum: a,
        blb7DaySumBgColor: b,
        blbAvgRisk: c,
        blbAvgRiskBgColor: d,
      }
    })

  const ipi = mainData
    .map((day) => modifiedBlightAlert(day, plantingDateDoy))
    .map((day, i, arr) => {
      // 7-day sum of days that meet the criteria of daily Modified Blight Alert (IPI) >= 7
      let a: string | number
      let b: string = ''

      let c: string | number
      let d: string = ''

      if (i < 7) {
        a = '-'
        c = '-'
      } else {
        const values = arr.slice(i - 6, i + 1)

        a = values.filter((d) => d.favorable).length
        if (a >= 1 && a <= 2) b = 'level-1'
        if (a >= 3 && a <= 4) b = 'level-2'
        if (a >= 5 && a <= 7) b = 'level-3'

        c = Number(mean(values.map((d) => d.ipiRisk)).toFixed(1))
        if (c >= 7 && c <= 9.99) d = 'level-1'
        if (c >= 11 && c <= 15.99) d = 'level-2'
        if (c >= 16 && c <= 19.99) d = 'level-3'
        if (c >= 20) d = 'level-4'
      }

      return {
        ipiRisk: day.ipiRisk,
        ipiRiskBgColor: day.ipiRiskBgColor,
        ipi7DaySum: a,
        ipi7DaySumBgColor: b,
        ipiAvgRisk: c,
        ipiAvgRiskBgColor: d,
      }
    })
  // console.log({ ipi })

  const allHrlyData = [...hourlyData, ...hourlyFcstData]
  const dnm = downyMildew(allHrlyData)
    .map((day, i, arr) => {
      let a: string | number
      let b: string = ''

      if (i < 7) {
        a = '-'
      } else {
        const values = arr.slice(i - 6, i + 1)

        a = values.filter((d) => d.sporulation === 'Favorable').length
        if (a >= 1 && a <= 2) b = 'level-2'
        if (a >= 3 && a <= 4) b = 'level-3'
        if (a >= 5 && a <= 7) b = 'level-4'
      }

      return {
        date: day.date,
        sporulation: day.sporulation,
        sporulationBgColor:
          day.sporulation === 'Favorable' ? 'level-4' : 'level-1',
        sporulation7DaySum: a,
        sporulation7DaySumBgColor: b,
      }
    })
    .slice(plantingDateDoy - 1)
  // console.log({ dnm })

  const priDlyData = purpleBlotchNew(allHrlyData)
  const pri = getAlt(priDlyData, plantingDateDoy).map((day, i, arr) => {
    let a: string | number
    let b: string = ''

    let c: string | number
    let d: string = ''

    if (i < 7) {
      a = '-'
      c = '-'
    } else {
      const values = arr.slice(i - 6, i + 1)

      a = values.filter((d) => d.favorable).length
      if (a >= 1 && a <= 2) b = 'level-1'
      if (a >= 3 && a <= 4) b = 'level-2'
      if (a >= 5 && a <= 7) b = 'level-3'

      c = Number(mean(values.map((d) => d.priRisk)).toFixed(1))

      if (c >= 4.5 && c <= 5.6) d = 'level-2'
      if (c >= 5.7 && c <= 6.6) d = 'level-3'
      if (c >= 6.7) d = 'level-4'
    }

    return {
      priRisk: day.priRisk,
      priRiskBgColor: day.priRiskBgColor,
      pri7DaySum: a,
      pri7DaySumBgColor: b,
      priAvgRisk: c,
      priAvgRiskBgColor: d,
    }
  })
  // console.log(pri)

  let combinedData = []
  for (let i = 0; i < mainData.length; i++) {
    const day = mainData[i]
    const blbDay = blb[i]
    const ipiDay = ipi[i]
    const dnmDay = dnm[i]
    const priDay = pri[i]

    combinedData.push({ ...day, ...ipiDay, ...blbDay, ...dnmDay, ...priDay })
  }
  // console.log(combinedData)

  const endIdx = combinedData.findIndex(
    (day) => day.dayOfYear === dateOfInterest.dayOfYear,
  )

  let resultsTable = []
  if (combinedData.length >= 6) {
    resultsTable = combinedData.slice(endIdx, endIdx + 6)
  }

  let sevenDaySummary = []

  function sliceTable(idx: number, data, max: number) {
    if (idx < max) return data.slice(0, idx)
    return data.slice(idx - max, idx)
  }
  sevenDaySummary = sliceTable(endIdx, combinedData, 6)

  const sevenDaySummaryCSV = combinedData.map((d) => {
    return {
      date: format(d.date, 'yyyy-MM-dd'),
      'BLB - (# of Days fav.)': d.blb7DaySum,
      'BLB - (Avg Rating per day)': d.blbAvgRisk,
      'IPI - (# of Days fav.)': d.ipi7DaySum,
      'IPI - (Avg Rating per day)': d.ipiAvgRisk,
      'Downy Mildew - (# of Days fav.)': d.sporulation7DaySum,
      'PRI - (# of Days fav.)': d.pri7DaySum,
      'PRI - (Avg Rating per day)': d.priAvgRisk,
    }
  })
  const resultsTableCSV = combinedData.map((d) => {
    return {
      date: format(d.date, 'yyyy-MM-dd'),
      BLB: d.blbRisk,
      IPI: d.ipiRisk,
      'Downy Mildew': d.sporulation,
      PRI: d.priRisk,
    }
  })

  // console.log({ plantingDate, resultsTable, sevenDaySummary })
  return {
    plantingDate,
    resultsTable,
    resultsTableCSV,
    sevenDaySummary,
    sevenDaySummaryCSV,
  }
}
