import React from "react"

export default function Acknowledgements() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
      This NEWA disease forecast tool was developed by Abby Seaman, NYS IPM Program, and Christie Hoepting, Cornell Vegetable Program, at Cornell University.
      </p>
    </>
  )
}
