import React from "react";
import BiofixDateInput from "../../components/models/biofixDateInput";
import ModelLayout from "../../components/models/modelLayout";
import GlobalStateContext from "../../context/globalStateContext";
import { useAuth0 } from "../../context/react-auth0-spa";
import { modelInSeason, sdateEdate } from "../../utils/helpers";
import useFetchStationData from "../../utils/hooks/useFetchStationData";
import useStations from "../../utils/hooks/useStations";
import modelData from "./onion-diseases.json";
import SevenDaySummaryTable from "./_7DaySummaryTable";
import Acknowledgments from "./_acknowledgements";
import modelLogic from "./_modelLogic";
import MoreInfo from "./_moreInfo";
import References from "./_references";
import ResultsTable from "./_resultsTable";

const visibleElements = Object.entries(modelData.elements)
  .filter(([_, value]) => value.priority)
  .filter(([key, _]) => !key.includes("user"))
  .map((arr) => ({ ...arr[1], toggleName: arr[0] }))
  .sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );

export default function OnionDiseases() {
  const h1Text = modelData.title;
  const { id, title, seasonEnd } = modelData;
  const { user, isAuthenticated } = useAuth0();
  const { stationList, station, setStation, favoriteStations, geoJSON } =
    useStations();
  const { dateOfInterest } = React.useContext(GlobalStateContext);
  const isModelInSeason = modelInSeason(dateOfInterest, modelData);

  const { sdate, edate } = sdateEdate(dateOfInterest, seasonEnd);
  const { isLoading, data } = useFetchStationData(station, sdate, edate);
  const [plantingDate, setPlantingDate] = React.useState(null);

  let mData = null;
  if (isModelInSeason) {
    mData = modelLogic(data, plantingDate, dateOfInterest);
  }

  const [isMoreInfo, setIsMoreInfo] = React.useState(false);
  const [showResultsTable1, setShowResultsTable1] = React.useState(true);
  const [showResultsTable2, setShowResultsTable2] = React.useState(true);
  const isVisible = station && mData;

  React.useEffect(() => {
    if (user && Object.keys(user.activeTools)) {
      const model = user.activeTools.find((model) => model.id === id);
      if (model) {
        if (
          Object.keys(model.biofix1).includes(`${dateOfInterest.year}`) &&
          Object.keys(model.biofix1[dateOfInterest.year]).includes(station.id)
        ) {
          setPlantingDate(model.biofix1[dateOfInterest.year][station.id]);
        } else {
          setPlantingDate(null);
        }
      }
    }
  }, [station, id, user, dateOfInterest]);

  return (
    <ModelLayout
      isAuthenticated={isAuthenticated}
      stationList={user ? favoriteStations : stationList}
      station={station}
      setStation={setStation}
      allStations={stationList}
      geoJSON={geoJSON}
      data={data}
      isModelInSeason={isModelInSeason}
      isLoading={isLoading}
      modelData={modelData}
      visibleElements={visibleElements}
      titleSize={"text-lg lg:text-2xl"}
      moreInfo={<MoreInfo station={station} moreInfo={modelData.moreInfo} />}
      setIsMoreInfo={setIsMoreInfo}
      references={References}
      acknowledgments={Acknowledgments}
      isMoreInfo={isMoreInfo}
      showResultsTable={showResultsTable1}
      setShowResultsTable={setShowResultsTable1}
      showResultsTable2={showResultsTable2}
      setShowResultsTable2={setShowResultsTable2}
      tutorialLink={modelData.tutorialLink}
      areStationsFiltered={false}
      h1Text={h1Text}
    >
      {!isLoading && (
        <div className="px-6 py-6 mb-16 sm:mb-18 md:mb-20">
          <div className="flex items-center justify-center py-6">
            <BiofixDateInput
              modelId={id}
              id={"biofix1"}
              dbKey="biofix1"
              // minDate={new Date(`${dateOfInterest.year}-03-21T00:00`)}
              maxDate={dateOfInterest.date}
              biofix={mData && mData.plantingDate}
              userBiofix={mData && mData.plantingDate}
              setUserBiofix={setPlantingDate}
              label="Planting Date"
              modelName={title}
              tooltip={`Reset biofix to default value`}
              datesWithNoData={[]}
              station={station}
            ></BiofixDateInput>
          </div>
        </div>
      )}
      {/* Infection Event Log */}
      {isModelInSeason && isVisible && (
        <>
          {showResultsTable1 && mData.sevenDaySummary.length !== 0 && (
            <div className="mb-16 sm:mb-18 md:mb-20">
              <SevenDaySummaryTable
                station={station}
                tableData={mData.sevenDaySummary}
                csv={mData.sevenDaySummaryCSV}
                dateOfInterest={dateOfInterest}
                title={modelData.elements.resultsTable.title}
              ></SevenDaySummaryTable>
            </div>
          )}

          {showResultsTable2 && (
            <div className="mb-16 sm:mb-18 md:mb-20">
              <ResultsTable
                station={station}
                tableData={mData.resultsTable}
                csv={mData.resultsTableCSV}
                dateOfInterest={dateOfInterest}
                title={modelData.elements.resultsTable2.title}
              ></ResultsTable>
            </div>
          )}
        </>
      )}
    </ModelLayout>
  );
}
