import React from "react"

export default function MoreInfo() {
  return (
    <div className="prose">
      <br />
      <h2>How to use Onion Disease Forecasts</h2>
      <h3>QUICK START</h3>
      <p>
        Please read the more detailed information below for a better
        understanding of the disease forecasts, but as a quick start ask
        yourself:
      </p>

      <h3>Is the disease present in my field?</h3>
      <strong>If the disease is present:</strong>

      <ul className="list-inside">
        <li>
          <strong>How favorable were conditions for the past 7 days?</strong>
          <p>
            If extremely or moderately favorable, choose a fungicide effective
            against that disease. See
            <a
              href="http://rvpadmin.cce.cornell.edu/uploads/doc_319.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-1"
            >
              link
            </a>
            for information on choosing the most effective fungicides.
          </p>
        </li>
        <li>
          <strong>How favorable are current and forecast conditions?</strong>
          <p>
            If the disease is forecast to be over threshold for more than three
            days, be sure fungicide coverage is maintained. If the disease is
            not forecast to be over threshold, you may be able to delay
            fungicide applications. Do not extend spray intervals for more than
            10 days and continue to monitor forecasts. Be sure to apply a
            fungicide before very favorable weather occurs.{" "}
            <strong>If the disease is not present</strong>, but conditions have
            been favorable, scout carefully to determine if the disease has
            appeared in your field. As new diseases are detected, adjust
            fungicide programs to include products that are effective against
            the mix of diseases that are present. See
            <a
              href="http://rvpadmin.cce.cornell.edu/uploads/doc_319.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-1"
            >
              link
            </a>
            for information on choosing the most effective fungicides.
          </p>
        </li>
      </ul>

      <h3>THE IMPORTANCE OF SCOUTING</h3>
      <p>
        While disease forecasting can be a useful component of your disease
        management program, it should{" "}
        <strong>only be used with a regular scouting program</strong>. Disease
        forecasts are not a substitute for scouting.
      </p>

      <h3>USING ONION DISEASE FORECASTS EFFECTIVELY</h3>
      <p>
        Weather-based disease forecasts are available for three fungal diseases
        of onions: Botrytis leaf blight (caused by <em>Botrytis squamosa</em>)
        purple blotch (caused by <em>Alternaria porri</em>) and downy mildew
        (caused by <em>Peronospora destructor</em>). There are separate
        forecasting programs for each disease. Each of the three diseases needs
        to be taken into account when using the forecasts to determine fungicide
        spray programs.
      </p>

      <h3>General information</h3>
      <p>
        The &quot;<strong>Number of days favorable</strong>&quot; and &quot;
        <strong>Average rating per day</strong>&quot; under the &quot;Past 7
        Days&quot; heading gives a general indication of disease pressure using
        the color guide below the results chart. The &quot;
        <strong>Today</strong>&quot; and <strong>5-Day forecast</strong> area
        will be red if it is over threshold and green if the forecast is not
        over threshold.
      </p>
      <p>
        Disease pressure, current forecast information plus scouting results and
        the days since the last fungicide application should all be taken into
        account when deciding on a fungicide application. In general expect 7
        days of protection from a fungicide application. If more than an inch of
        rain has fallen, assume that you've lost the protection from the last
        fungicide application.
      </p>
      <p>
        <strong>Fungicide choice</strong> will depend on which diseases have
        been detected in the field and how favorable conditions are for disease.
        When conditions are extremely favorable, fungicides with the best
        performance for the disease should be selected, or the highest label
        rate used when there is a range of rates. See
        <a
          href="http://rvpadmin.cce.cornell.edu/uploads/doc_319.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="mx-1"
        >
          link
        </a>
        for information on choosing the most effective fungicides.
      </p>
      <p>
        When conditions are not favorable for a disease, less emphasis may be
        placed on controlling it, provided scouting information confirms that
        the disease is at low levels.
      </p>

      <h3>Botrytis Leaf Blight Forecasts</h3>
      <p>
        There are two different disease forecasts available for Botrytis leaf
        blight; Modified Blight Alert, developed in New York by Dr. Paul
        Vincelli and Dr. Jim Lorbeer at Cornell University, and the Michigan
        Botrytis Forecast developed by Dr. Mel Lacey at Michigan State
        University. Blight Alert was developed in New York and is the most
        appropriate forecast to use for New York conditions. The Michigan
        forecast can help with scheduling early season scouting and provide
        additional information to use when making a spray decision for Botrytis.
      </p>

      <h3>Michigan Botrytis forecast</h3>
      <p>
        The Michigan Botrytis forecast predicts the release of Botrytis spores.
        The forecast indicates whether there was a significant inoculum
        potential when the <strong>BLB value is 50 or higher</strong>. A
        significant inoculum potential indicates that spores have been released
        and that there is a risk of infection.
      </p>
      <p>
        This model is a good early season warning system to determine if blight
        conditions exist prior to scouting. It is also a good indicator for
        scheduling the first scouting of the season.
      </p>

      <h3>Modified Blight Alert</h3>
      <p>
        A{" "}
        <strong>
          combination of scouting, thresholds and weather-based forecasts
        </strong>{" "}
        is used to determine fungicide programs for Botrytis leaf blight using
        Blight Alert.
      </p>
      <p>
        The first fungicide is applied when scouting determines that there is an
        average of one Botrytis lesion per leaf, the{" "}
        <strong>Inoculum production index (IPI) is 7 or greater</strong>, and
        there is a 30% or greater chance of rain. See the Cornell Integrated
        Crop and Pest Management Guidelines for Commercial Vegetable Production
        for information on scouting.
      </p>
      <p>
        Subsequent applications are determined by how favorable past weather has
        been and how many days are forecast to be over threshold in the future,
        keeping in mind that fungicide protection is gone if more than one inch
        of rain has fallen since the last application. When Modified Blight
        Alert indicates very favorable for 5-7 days, than BLB needs to be
        actively managed when the disease is present in the crop.
      </p>
      <p>
        In general, when both Modified Blight Alert and Michigan Botrytis
        Forecast models are indicating favorable conditions, than disease
        pressure is higher than if just one disease model is predicting
        actionable disease pressure. When this occurs, effective fungicides for
        BLB should be applied.
      </p>

      <h3>Downy Mildew Forecast</h3>
      <p>
        The downy mildew forecast (DOWNCAST) was developed in Ontario, Canada by
        Dr. P.D. Hildebrand and Dr. J. C. Sutton at the University of Guelph.
        DOWNCAST predicts sporulation of <em>Peronopora destructor</em>,the
        fungus that causes downy mildew, <strong>if it is present</strong>.
      </p>
      <p>
        The forecast indicates whether the weather was favorable or unfavorable
        for sporulation. Occasional favorable days in areas where downy mildew
        has not been reported are not cause for concern or special fungicide
        applications, but are a heads-up to look for downy mildew when scouting.
      </p>
      <p>
        Because the downy mildew forecast includes rainfall in the calculations,
        only two days of forecast is available. The national weather service
        does not include rainfall in forecasts more than two days out.
      </p>
      <p>
        If longer periods of greater than 5 out of 7 days of favorable weather
        have occurred, especially once downy mildew is present, maintain
        excellent coverage with protectant fungicides and scout fields carefully
        for downy mildew, which can be very destructive.
      </p>
      <p>
        Finding downy mildew in your field or the immediate vicinity would be
        cause for switching to specific fungicides targeting downy mildew. See
        <a
          href="http://rvpadmin.cce.cornell.edu/uploads/doc_319.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="mx-1"
        >
          link
        </a>
        for information on choosing the most effective fungicides.
      </p>

      <h3>Alternaria Purple Blotch Forecast</h3>
      <p>
        The Alternaria forecast was developed in Michigan by Dr. Mel Lacey. Dr.
        Brian Cortwright and Christy Hoepting have been working to fine tune the
        forecast. The forecast uses temperature and relative humidity
        information to calculate a predictive index (PRI) that predicts the
        release of Alternaria porri (purple blotch) spores. A PRI of 5.7 or
        greater indicates a high risk of purple blotch infection. A PRI less
        than 5.7 indicates a low risk.
      </p>

      <h3>Stemphylium leaf blight</h3>
      <p>
        Since Stemphylium leaf blight (SLB) is favored by very similar
        conditions as purple blotch, the purple blotch disease forecast may also
        serve as an indication of when conditions are favorable for SLB. SLB
        also appears to be related to plant stress; healthy plants are less
        prone to SLB despite favorable conditions.
      </p>
      <p>
        Generally, once bulbing commences, onion plants appear to be more prone
        to SLB and Purple Blotch; typically fungicides with activity against
        these diseases commence at this time (July). See
        <a
          href="http://rvpadmin.cce.cornell.edu/uploads/doc_319.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="mx-1"
        >
          link
        </a>
        for information on choosing the most effective fungicides.
      </p>
    </div>
  )
}
